<template>
  <div :class="$vuetify.breakpoint.mdAndDown ? 'pt-2 pb-4' : 'py-4'">
    <v-row no-gutters class="font-weight-bold" align="end">
      {{ $t(`label.referrals`) }}
    </v-row>
    <v-row no-gutters justify="center" class="mx-0">
      <!-- <v-col cols=12 class="text-right">
             <v-chip pill color="yellow" class="get-coins-chip">
                    <label class="text-capitalize font-weight-bold pa-2">
                                Get 100 Coins
                                </label>
                </v-chip>
        </v-col> -->
      <v-col cols="12" class="pt-2">
        <v-card light class="pa-4 mb-1 mr-1 referral-container" outlined>
          <v-row class="pa-0">
            <v-col cols="6" sm="5">
              <vue-qr-code v-if="memberReferralCode != null" class="qrCode mx-auto" :text="share_url" :size="$vuetify.breakpoint.mdAndDown ? 100 : 150"></vue-qr-code>
              <v-img :aspect-ratio="$vuetify.breakpoint.smAndDown ? 140 / 90 : 500 / 144" v-else src="https://cdn.vuetifyjs.com/images/cards/cooking.png" width="auto" alt />
            </v-col>
            <v-col cols="6" sm="7" v-if="!isLoggedIn" :class="$vuetify.breakpoint.xsOnly ? 'px-0' : ''">
              <v-row no-gutters justify="space-between">
                <v-col cols="12" sm="8" lg="8" md="8">
                  <p class="mb-0">{{ $t(`label.referFriendRewards`) }}</p>
                  <p v-if="!$vuetify.breakpoint.xsOnly">{{ $t(`label.referralLogin`) }}</p>
                </v-col>
                <v-col class="text-right" cols="12" sm="4" lg="4" md="4" align-self="end">
                  <app-button :action="this.toReferralTier" :title="$t(`button.moreInfo`)"></app-button>
                </v-col>
              </v-row>
            </v-col>
            <v-col cols="6" sm="7" v-if="isLoggedIn">
              <v-row no-gutters justify="space-between">
                <v-col cols="12">
                  <p class="mb-0">{{ $t(`field.referralCode`) }}:</p>
                </v-col>
                <v-col cols="12" class="referral-progress-bar px-1 text-left font-weight-bold">
                  <v-icon color="black" @click="copyMemberCode()" small class="pr-1">content_copy</v-icon>
                  <span ref="memberReferralCode">{{ memberReferralCode }}</span>
                </v-col>
                <v-col cols="12" class="mt-3">
                  <span :class="$vuetify.breakpoint.xsOnly ? 'py-1' : 'py-1'">{{ stringFormat($t(`label.commissionPercentage`), currentMonth) }}</span>
                </v-col>
                <v-col cols="12" class="referral-progress-bar px-1 text-left font-weight-bold">
                  <span>{{ referralOverview ? referralOverview.commission_month : 0 | currency(currentCurrency) }}</span>
                </v-col>
                <v-row class="mt-5" v-if="!$vuetify.breakpoint.xsOnly" no-gutters justify="end" align="end">
                  <v-col class="text-center mr-4" cols="6" lg="3" md="3" sm="3" align-self="end">
                    <v-btn height="auto" class="full-width font-weight-bold pa-2 primary-button buttonPrimary theme-button theme--light" @click="share()">
                      <v-icon class="mr-2">share</v-icon>
                      {{ $t(`button.shareNow`) }}
                    </v-btn>
                  </v-col>
                  <v-col class="text-center" cols="6" lg="3" md="3" sm="3" align-self="end">
                    <!-- <app-button color="black" class="primary-button" :action="''" :title="$t(`button.summary`)"></app-button> -->
                    <v-btn height="auto" color="black" class="primary-button pa-2 font-weight-bold yellow--text" href :to="{ name: routeName.REFERRAL_REPORT }">{{ $t(`button.summary`) }}</v-btn>
                  </v-col>
                </v-row>
              </v-row>
            </v-col>
          </v-row>
          <v-row v-if="isLoggedIn && $vuetify.breakpoint.xsOnly" justify="space-around" class="mt-2" no-gutters align="end">
            <v-col class="text-left" cols="6" align-self="end">
              <!-- <app-button style="width:100%;" class="mr-2" :action="this.toReferral" :title="$t(`button.shareNow`)"></app-button> -->
              <v-btn class="mr-2 full-width font-weight-bold pa-2 primary-button buttonPrimary theme-button theme--light" @click="share()">
                <v-icon class="mr-2">share</v-icon>
                {{ $t(`button.shareNow`) }}
              </v-btn>
            </v-col>
            <v-col class="text-left" cols="6" align-self="end">
              <!-- <app-button color="black" class="primary-button" :action="''" :title="$t(`button.summary`)"></app-button> -->
              <v-btn color="black" class="ml-2 primary-button pa-1 font-weight-bold yellow--text" href width="100%" :to="{ name: routeName.REFERRAL_REPORT }">{{ $t(`button.summary`) }}</v-btn>
            </v-col>
          </v-row>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import VueQrCode from 'vue-qrcode-component'
import stringFormat from 'string-format'
import { formValidator, uiHelper } from '@/util'
import { SHARED } from '@/constants/constants'
import { REFERRAL_GET_OVERVIEW } from '@/store/referral.module'
import { ROUTE_NAME } from '@/constants/route.constants'
import { locale } from '@/util/localization-helper'
export default {
  name: 'appHomeReferral',
  data: () => ({
    stringFormat: stringFormat,
    routeName: ROUTE_NAME,
    currentCurrency: uiHelper.getCurrency(),
    referralProgress: 0,
    share_url: ''
  }),
  created() {
    this.getReferralOverview()
  },
  components: {
    VueQrCode
  },
  computed: {
    isLoggedIn() {
      return this.$store.state.member.isLoggedIn
    },
    memberReferralCode() {
      let d = this.$store.state.member.info.mbrRefferalCode
      if (window.location.host.toLowerCase() === 'aa7813910.com') {
        this.share_url = 'https://www.jaya9.app' + '?refer_code=' + d
      } else {
        // If not, construct the share URL normally
        this.share_url = uiHelper.getHostname() + '?refer_code=' + d
      }
      return this.$store.state.member.info.mbrRefferalCode
    },
    referralOverview() {
      return this.$store.state.referral.overview
    },
    currentMonth() {
      let months = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December']
      let d = new Date()
      return months[d.getMonth()]
    }
  },
  watch: {
    isLoggedIn() {
      this.getReferralOverview()
    }
  },
  methods: {
    getReferralOverview() {
      if (this.isLoggedIn) {
        this.$store.dispatch(`${REFERRAL_GET_OVERVIEW}`)
      }
    },
    toReferral() {
      this.$router.push({
        name: ROUTE_NAME.REFERRAL_SUMMARY
      })
    },
    toReferralTier() {
      this.$router.push({
        name: ROUTE_NAME.REFERRAL_TIER
      })
    },
    share() {
      navigator.share({
        title: 'Refer a friend now and earn commission',
        text: 'Your referral code is ' + this.memberReferralCode + '.Refer a friend now and earn commission now!',
        url: this.share_url
      })
    },
    copyMemberCode() {
      // this.$refs.memberReferralCode.select();
      // document.execCommand("copy");
      navigator.clipboard.writeText(this.memberReferralCode)
    }
  }
}
</script>

<style lang="scss">
// .v-card {
//     border-radius: 5px 0 0 5px !important;
// }
.referral-container {
  border-radius: 20px !important;
  .referral-progress-bar {
    // background-color: #EBEBEB;
    border: 2px solid #fddd04;
    border-radius: 5px; // .v-progress-linear__background{
    //     background-color: #EBEBEB !important;
    //     border-color: #EBEBEB !important;
    //     opacity: 1 !important;
    // }
    // .v-progress-linear__determinate{
    //     border-radius: 10px;
    //     background-color: #FDDD04 !important;
    //     border-color: #FDDD04 !important;
    // }
  }
}
.qrCode > img {
  border: 2px solid black;
  padding: 20px;
  margin: 0 auto;
}
.get-coins-chip {
  margin: 0 30px -30px 0;
  z-index: 1;
}
@media (max-width: 959px) {
  .referral-container {
    border-radius: 20px !important;
    font-size: 14px !important;
    p {
      font-size: 14px !important;
    }
    .v-btn.primary-button {
      width: 100% !important;
      height: 30px !important;
      font-size: 12px !important;
    }
  }
  .qrCode > img {
    padding: 10px;
  }
}
</style>
